const config = {
  apiGateway: {
    REGION: 'ap-southeast-2',
    INTERNAL_API_URL: 'https://xk3orzt712.execute-api.ap-southeast-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://5lktp971hk.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.10.2',
    HOSTNAME: 'https://company.newcrest.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.newcrest.forwoodsafety.com',
    WEBSOCKET: 'wss://okh6i0qqgi.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
};

export default config;